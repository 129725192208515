import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import AOS from "aos";
import "aos/dist/aos.css"; 
import { useTranslation } from "react-i18next";

import useNumberAnimation from "../../hooks/useNumberAnimation";
import AppStore from "../../assets/icon/ic_app_store.svg";
import GooglePlay from "../../assets/icon/ic_google_play.svg";
import AppStoreMobile from "../../assets/icon/ic_app_store_m@3x.png"
import GooglePlayMobile from "../../assets/icon/ic_google_play_m@3x.png";
import IntroBg from "../../assets/image/intro_bg@3x.png";
import IntroBgMobile from "../../assets/image/Intro_bg_m.png";
import BapePlanet from "../../assets/image/intro_bape@3x.png";
import MetaCarbonBg from "../../assets/image/intro_metacarbon_bg@3x.png";
import MetaCarbonBgMobile from "../../assets/image/img_background_market_mobile.png";
import CarbonIncomeBg from "../../assets/image/intro_metaCarbonIncome_bg@3x.png";
import CarbonIncomeBgMobile from "../../assets/image/intro_metaCarbonIncome_bg_m.png";
import CarbonGraph from "../../assets/image/intro_metaCarbon_graph.svg";



const Intro = () => {

    const { t, i18n } = useTranslation();
    const isKorean = i18n.language === "ko";
    const [bapeNumber1, refBapeNumber1] = useNumberAnimation(t(`intro.bapeNumber1`), 3000, t(`intro.bapeStep1`));
    const [bapeNumber2, refBapeNumber2] = useNumberAnimation(t(`intro.bapeNumber2`), 3000, t(`intro.bapeStep2`));
    const [currentPrice, refCurrentPrice] = useNumberAnimation(87.15, 1000, 3);


    useEffect(() => {
        AOS.init();
    }, []);

    let formattedBapeNumber1 = isKorean ? Math.floor(bapeNumber1) : bapeNumber1;
    let formattedBapeNumber2 = isKorean ? Math.floor(bapeNumber2) : bapeNumber2;

    const metaCarbon = [
        {
            title: t(`intro.metaCarbonSubtitle01`),
            content: t(`intro.metaCarbonContents01`),
        },
        {
            title: t(`intro.metaCarbonSubtitle02`),
            content: t(`intro.metaCarbonContents02`),
        },
        {
            title: t(`intro.metaCarbonSubtitle03`),
            content: t(`intro.metaCarbonContents03`),
        },
        {
            title: t(`intro.metaCarbonSubtitle04`),
            content: t(`intro.metaCarbonContents04`),
        },
    ];
    
    const handleDownload = () => {
        const downloadLink = document.createElement("a");
        downloadLink.href = "../../assets/mist.apk";
        downloadLink.download = "mist.apk";
        downloadLink.click();
    };
    

    const isMobile = useMediaQuery({ maxWidth: 768 });
    return (
        <div id="IntroSection" className="whitespace-pre-wrap">
            <div
                className="bg-black md:pt-[204px] pt-[225px] md:pb-[152px] pb-[281px] flex flex-col justify-center items-center bg-cover bg-center"
                style={{
                    backgroundImage: `url(${isMobile ? IntroBgMobile : IntroBg})`,
                }}>
                <div className="text-white font-bold md:text-[72px] text-[40px] text-center">
                    <div data-aos="fade-down" data-aos-delay="1000">
                        {t(`intro.main`)}
                    </div>
                </div>
                {/* 다운로드 버튼 */}
                <div className="md:flex md:mt-[62px] mt-[28px]">
                    <img
                        src={isMobile ? GooglePlayMobile : GooglePlay}
                        className="md:w-[200px] w-[160px] md:h-[70px] h-[56px] mr-[12px] cursor-pointer"
                        onClick={() => handleDownload()}
                    />
                    <img
                        src={isMobile ? AppStoreMobile : AppStore}
                        className="md:w-[200px] w-[160px] md:h-[70px] h-[56px] mt-[12px] md:mt-0"
                    />
                </div>
            </div>

            {/* 확장되는 베이프 부분 */}
            <div
                className="md:flex md:py-[144px] py-[100px] px-[20px]  text-white bg-black md:px-[18%] md:justify-between md:min-w-[1200px] relative "
                style={{ overflow: "hidden" }}>
                <div className="xl:w-[65%]">
                    <div className="2xl:text-[50px] xl:text-[46px] text-[28px] font-extrabold ">
                        {t(`intro.bapeTitle`)}
                    </div>
                    <div className="2xl:text-[28px] xl:text-[24px] text-[14px] md:mt-[100px] mt-[44px] md:mb-[52px] mb-[28px]">
                        {t(`intro.bapeContents`)}
                    </div>
                    <div
                        className="border-[1px] border-white md:w-[228px] w-[150px] md:h-[68px] h-[53px] font-semibold md:text-[30px] text-[20px] flex justify-center items-center cursor-pointer"
                        onClick={() =>
                            window.open(
                                "https://www.nosmokeguide.go.kr/lay2/bbs/S1T33C44/H/65/view.do?article_seq=819520&only_one=Y",
                                "_blank"
                            )
                        }>
                        {t(`intro.bapeButton`)}
                    </div>
                </div>
                <div className="md:mt-[155px] mt-[44px] relative">
                    <div ref={refBapeNumber1}>
                        <div className="2xl:text-[70px] xl:text-[66px] text-[44px] font-black flex ">
                            <p>$</p>
                            <div className="md:w-[142px] w-[90px] text-end">{formattedBapeNumber1}</div>
                            <p>{t(`intro.bapeBillions`)}</p>
                        </div>
                        <div className="text-[#5F5F5F] 2xl:text-[28px] xl:text-[24px] text-[12px] font-medium">
                            {t(`intro.2021year`)}
                        </div>
                    </div>
                    <div className="md:mt-[62px] mt-[40px]" ref={refBapeNumber2}>
                        <div className="2xl:text-[70px] xl:text-[66px] text-[44px] font-black flex ">
                            <p>$</p>
                            <div className="md:w-[170px] w-[106px] text-end">{formattedBapeNumber2}</div>
                            <p>{t(`intro.bapeBillions`)}</p>
                        </div>
                        <div className="text-[#5F5F5F] 2xl:text-[28px] xl:text-[24px] text-[12px] font-medium">
                            {t(`intro.2026year`)}
                        </div>
                    </div>
                </div>

                <img
                    src={BapePlanet}
                    className="mix-blend-screen 2xl:w-[773px] xl:w-[600px] 2xl:h-[773px] absolute 2xl:mt-[-100px] 2xl:ml-[700px] xl:ml-[600px] xl:mt-[-50px] mt-[-600px] "
                    style={{ pointerEvents: "none" }}
                />
            </div>

            {/* 메타카본 부분 */}
            <div className="text-white  bg-black py-[144px] ">
                <p className="xl:text-[50px] text-[28px] font-extrabold text-center md:mb-[100px] mb-[44px]">
                    {t(`intro.metaCarbonTitle`)}
                </p>
                <div className="flex  h-[700px] mb-[40px] ">
                    <img src={MetaCarbonBg} className="absolute  xl:h-[600px] 2xl:h-[697px]  hidden md:flex" />
                    <div
                        className="w-full h-[500px] bg-cover md:hidden"
                        style={{ backgroundImage: `url(${MetaCarbonBgMobile})` }}></div>
                    <div className="xl:grid xl:grid-cols-2 2xl:gap-x-[92px] xl:gap-x-[88px]  2xl:gap-y-[106px] xl:gap-y-[100px] md:w-1/2 z-1 absolute md:right-[160px] px-[20px] md:px-0">
                        {metaCarbon.map((item) => (
                            <div>
                                <div className="2xl:text-[40px] xl:text-[36px] text-[24px] font-bold">{item.title}</div>
                                <div className="2xl:text-[26px] xl:text-[22px] text-[14px] mt-[28px] mb-[44px] md:mb-0">
                                    {item.content}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* 메타카본의 수익모델 */}
            <div className=" bg-black text-white">
                <div
                    className="md:pb-[144px] pb-[100px] bg-cover bg-center md:px-[14%] px-[20px] md:pt-[144px] pt-[100px] "
                    style={{
                        backgroundImage: `url(${isMobile ? CarbonIncomeBgMobile : CarbonIncomeBg})`,
                    }}>
                    <p className="2xl:text-[50px] xl:text-[46px] text-[28px] font-extrabold 2xl:mb-[44px] xl:mb-[40px] mb-[24px]">
                        {t(`intro.revenueModelTitle`)}
                    </p>
                    <div className="2xl:text-[26px] xl:text-[22px] text-[14px]">{t(`intro.revenueModelcontent01`)}</div>

                    <p className="2xl:text-[40px] xl:text-[36px] text-[24px] font-bold mt-[100px] ">
                        {t(`intro.revenueModelSubtitle`)}
                    </p>
                    <div className="md:flex md:items-center md:justify-between w-full ">
                        <div className="flex-1 md:mr-[74px]">
                            <span className="2xl:text-[26px] xl:text-[22px] text-[14px] ">
                                <div className="md:mt-[62px] mt-[24px]">
                                    {t(`intro.revenueModelcontent02`)}
                                    <br />
                                </div>
                                <div className="md:mt-[62px] mt-[24px]">{t(`intro.revenueModelcontent03`)}</div>
                            </span>
                            <div className="md:flex md:mt-[72px] mt-[62px]">
                                <div className="text-[36px] md:mr-[92px]">
                                    <p className="font-black">{t(`intro.updateScheduled`)}</p>
                                    <p className="text-[#5F5F5F] md:text-[24px] text-[12px] font-medium">
                                        {t(`intro.carbonCreditsOwned`)}
                                    </p>
                                </div>
                                <div className="text-[36px] ">
                                    <p className="font-black">{t(`intro.updateScheduled`)}</p>
                                    <p className="text-[#5F5F5F] md:text-[24px] text-[12px] font-medium">
                                        {t(`intro.currentValue`)}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 flex flex-col items-end " ref={refCurrentPrice}>
                            <img src={CarbonGraph} />
                            <p className="2xl:text-[70px] xl:text-[66px] text-[44px] font-black mt-[62px] md:mt-[52px]">
                                ${currentPrice}
                            </p>
                            <p className="md:text-[28px] text-[12px] text-[#5F5F5F] font-medium ">
                                {t(`intro.currentPrice`)}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Intro;
