import React, { useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import Partner1 from "../../assets/logo/ic_partner_01.png";
import Partner2 from "../../assets/logo/ic_partner_02.png";
import Partner3 from "../../assets/logo/ic_partner_03.png";
import Partner4 from "../../assets/logo/ic_partner_04.png";
import Partner5 from "../../assets/logo/ic_partner_05.png";
import Partner6 from "../../assets/logo/ic_partner_06.png";
import Partner7 from "../../assets/logo/ic_partner_07.png";
import Partner8 from "../../assets/logo/ic_partner_08.png";
import Partner9 from "../../assets/logo/ic_partner_09.png";
import Partner10 from "../../assets/logo/ic_partner_10.png";
import Partner11 from "../../assets/logo/ic_partner_11.png";
import Bg from "../../assets/image/img_background_partner.png";

const partners = [
    Partner1,
    Partner2,
    Partner3,
    Partner4,
    Partner5,
    Partner6,
    Partner7,
    Partner8,
    Partner9,
    Partner10,
    Partner11,
];
const Partners = () => {

    const containerRef = useRef(null);

    return (
        <div
            id="PartnersSection"
            className="md:py-[144px] py-[100px] bg-black bg-cover bg-center md:pb-[200px]"
            style={{ backgroundImage: `url(${Bg})` }}>
            <div className="font-extrabold md:text-[50px] text-[28px] text-center text-white md:mb-[100px] mb-[77px]">
                Partners
            </div>
            {/* 파트너 아이콘 리스트 */}

            <div className="flex overflow-scroll scrollbar-hide">
                {partners.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        className="md:h-[60px] h-[30px] md:mr-[110px] mr-[20px] h-[100px] mb-[34px] md:mb-0"
                    />
                ))}
            </div>
        </div>
    );
};

export default Partners;
