import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; 

import Intro from "./components/sections/Intro";
import SmokeToEarn from "./components/sections/smokeToEarn/SmokeToEarn";
import Economy from "./components/sections/Economy";
import Product from "./components/sections/Product";
import Team from "./components/sections/Team";
import Mist from "./components/sections/Mist";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Partners from "./components/sections/Partners";
import PopUp from "./components/PopUp";
function App() {
    useEffect(() => {
        AOS.init(); 
    }, []);
    return (
        <div className="md:min-w-[1440px]">
            <Navbar />
            <Intro />
            <Economy />
            <SmokeToEarn />
            <Product />
            <Team />
            <Mist />
            <Partners />
            <Footer />
            <PopUp />
        </div>
    );
}

export default App;
