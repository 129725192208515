import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

const useNumberAnimation = (targetNumber, duration, step) => {
    const [count, setCount] = useState(0);
    const [ref, inView] = useInView({
        triggerOnce: false,
    });

    useEffect(() => {
        if (inView && count < targetNumber) {
            const interval = setInterval(() => {
                const nextCount = Math.min(count + step, targetNumber);
                setCount(nextCount);
            }, duration / (targetNumber / step));

            return () => clearInterval(interval);
        } else if (count > targetNumber) {
            setCount(targetNumber);
        }
    }, [count, inView, targetNumber, duration, step]);
      
      return [count, ref];
};

export default useNumberAnimation;
