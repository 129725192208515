import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../locales/i18n";
import { Link } from "react-scroll";

import HomeLogo from "../assets/logo/ic_home_logo.png";
import Ko from "../assets/image/ko@2x.png";
import En from "../assets/image/en@2x.png";
const Navbar = () => {
    const [activeMenu, setActiveMenu] = useState(null);
    const { i18n, t } = useTranslation();
    const isKorean = i18n.language === "ko";
    const handleSetActive = (to) => {
        setActiveMenu(to);
    };

    const handleLanguageToggle = (language) => {
        i18n.changeLanguage(language);
    };

    const MENUS = [
        {
            title: t(`navbar.intro`),
            sectionId: "IntroSection",
        },
        {
            title: t(`navbar.economy`),
            sectionId: "EconomySection",
        },
        {
            title: t(`navbar.smokeToEarn`),
            sectionId: "SmokeToEarnSection",
        },
        {
            title: t(`navbar.product`),
            sectionId: "ProductSection",
        },
        {
            title: t(`navbar.team`),
            sectionId: "TeamSection",
        },
        {
            title: t(`navbar.mist`),
            sectionId: "MistSection",
        },
        {
            title: t(`navbar.partners`),
            sectionId: "PartnersSection",
        },
        {
            title: t(`navbar.buy`),
            sectionId: "BuySection",
        },
    ];

    return (
        <div className="md:h-[88px] h-[56px] md:pl-[40px] md:pr-[30px] px-[20px] flex items-center justify-between sticky top-0 bg-transparent md:mt-[-88px] mt-[-56px]  z-20 ">
            <img
                className="cursor-pointer md:h-[30px] h-[20px]"
                src={HomeLogo}
                onClick={() => (window.location.href = "https://metacarbon.net")}
            />
            <div className="flex">
                <ul className="md:flex  hidden items-center">
                    {MENUS.map((menu) => {
                        return (
                            <li key={menu.sectionId}>
                                <Link
                                    activeClass="text-white"
                                    to={menu.sectionId}
                                    spy={true}
                                    smooth={true}
                                    offset={50}
                                    duration={500}
                                    className={`mr-[31px] cursor-pointer ${
                                        activeMenu === menu.sectionId ? "text-white" : "text-[#ABA9A7]"
                                    }`}
                                    onSetActive={() => handleSetActive(menu.sectionId)}>
                                    {menu.title}
                                </Link>
                            </li>
                        );
                    })}
                </ul>

                <div className="flex">
                    <img
                        src={En}
                        className={`md:w-[37px] w-[22px] mr-[10px] cursor-pointer ${isKorean ? "opacity-40" : ""}`}
                        onClick={() => handleLanguageToggle("en")}
                    />
                    <img
                        src={Ko}
                        className={`md:w-[37px] w-[22px] cursor-pointer ${isKorean ? "" : "opacity-40"}`}
                        onClick={() => handleLanguageToggle("ko")}
                    />
                </div>
            </div>
        </div>
    );
};

export default Navbar;
