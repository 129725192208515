import React from "react";
import HomeLogo from "../assets/logo/ic_home_logo.png";
import TelegramKo from "../assets/foot-bar/ic_footer_telegram_ko.svg";
import TelegramEn from "../assets/foot-bar/ic_footer_telegram_glo.svg";
import Twitter from "../assets/foot-bar/ic_footer_twitter_ko.svg";
const Footer = () =>{
    return (
        <div className="md:h-[232px] h-[339px]  bg-[#121212] md:px-[300px] px-[20px] pt-[48px]  text-white md:flex justify-between ">
            <div>
                <img src={HomeLogo} className=" h-[30px] mb-[26px]" />
                <div className="text-[#ABA9A7] md:font-base text-[16px]">
                    Meta Carbon Foundation Co, Ltd is a technology enterprise
                    <br /> with blockchain as its core technology
                </div>
            </div>

            <div className="hidden md:flex md:flex-col">
                <div>SOCIALS</div>
                {/* SNS 리스트 목록*/}
                <div className="flex mt-[20px]">
                    <img
                        src={TelegramKo}
                        className="mr-[19px] cursor-pointer"
                        onClick={() => window.open("https://t.me/metacarbon_kr")}
                    />
                    <img
                        src={TelegramEn}
                        className="mr-[19px] cursor-pointer"
                        onClick={() => window.open("https://t.me/metacarbon_net")}
                    />
                    <img src={Twitter} className="mt-[-20px]"/>
                </div>
                <div className="text-[#ABA9A7] mt-[10px] ">Connect Us : service@metacarbon.net</div>
            </div>
        </div>
    );
}

export default Footer;