import React from "react";
import { useTranslation } from "react-i18next";

import Graph from "../../assets/image/mist_graph.png";
import WhitePaper from "../../assets/icon/ic_mist_whitepaper.png";
const Mist = () =>{
    const graphData = [
        {
            dataPoints: "Mining 75%",
            color: "bg-[#404693]",
        },
        {
            dataPoints: "TEAM 10% ( Lock 2 Year )",
            color: "bg-[#723FE0]",
        },
        {
            dataPoints: "Seed 9% ( Lock 1 Year ) – 0.005$",
            color: "bg-[#707AEA]",
        },
        {
            dataPoints: "Marketing 5%",
            color: "bg-[#BD89FF]",
        },
        {
            dataPoints: "IDO 1% ( No Lock ) – 0.01$",
            color: "bg-[#E6D2FF]",
        },
    ];

    const { i18n } = useTranslation();
    const isKorean = i18n.language === "ko";

    const openFiles =() => {
        isKorean
            ? window.open(
                  "https://firebasestorage.googleapis.com/v0/b/mist-7fb88.appspot.com/o/MetaCarbonWhitePaper-KR.pdf?alt=media&token=d8b80be2-2e2a-4c87-aeba-60914aaf0706"
              )
            : window.open(
                  "https://firebasestorage.googleapis.com/v0/b/mist-7fb88.appspot.com/o/MetaCarbonWhitePaper-EN.pdf?alt=media&token=3b98543c-8dc3-4a1e-811d-e3709e8f4caa"
              );
    };
    return (
        <>
            <div id="MistSection" className="md:py-[144px] py-[100px] bg-black">
                <section className="md:flex md:justify-center items-center ">
                    <img src={Graph} className="md:mr-[72px] md:w-[540px] md:mx-0 mx-auto  w-[320px]" />
                    <div className=" md:my-auto mt-[42px] flex md:flex-col items-start   flex-wrap   md:mx-0  mx-auto ml-[20px]">
                        {graphData.map((data) => (
                            <div className="flex  md:mb-[36px] mr-[20px] mb-[12px]">
                                <div
                                    className={`md:w-[32px] w-[14px] md:h-[32px] h-[14px]  md:rounded-md rounded-sm md:mr-[20px] mr-[8px] ${data.color}`}
                                    key={data.dataPoints}></div>
                                <div className="md:text-[22px] text-[12px] text-white">{data.dataPoints}</div>
                            </div>
                        ))}
                    </div>
                </section>
            </div>

            {/* Whitepaper 부분 */}
            <div className="md:py-[144px] py-[100px] bg-black grid justify-center">
                <div className="font-extrabold md:text-[50px] text-[28px] text-white md:mb-[100px] mb-[42px] text-center">
                    Whitepaper
                </div>
                <img
                    src={WhitePaper}
                    className="md:w-[320px] w-[160px]  mx-auto cursor-pointer"
                    onClick={() => openFiles()}
                />
            </div>
        </>
    );
}

export default Mist;

