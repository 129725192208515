import React from "react";
import { useTranslation } from "react-i18next";

import ProductCup from "../../assets/image/product_mini_cup.png";
import ProductCupMobile from "../../assets/image/product_mini_cup_m.png";
import Product1 from "../../assets/image/product_6ml@3x.png";
import Product2 from "../../assets/image/product_15ml@3x.png";
import MistBg from "../../assets/image/img_background_product.png"
const Product = () =>{
    const { t, i18n } = useTranslation();
    const isKorean = i18n.language === "ko";
    const products = [
        {
            "Battery Capacity": "600MAH",
            "Net Content": "6ML",
            Puffs: "3500",
            Bluetooth: "5.0",
            Charging: "Type-c",
            Nicotine: "0.9%",
            Function: "One-Time Mining",
        },
        {
            "Battery Capacity": "650MAH",
            "Net Content": "15ML",
            Puffs: "6000",
            Bluetooth: "5.0",
            Charging: "Type-c",
            Nicotine: "0.9%",
            Function: "One-Time Mining",
        },
    ];
    return (
        <div id="ProductSection" className="md:pt-[100px] pt-[62px] bg-black">
            <div className="text-white md:text-[90px] text-[40px] text-center font-extrabold">ZIGLE MIST</div>
            <img src={ProductCup} className="md:flex hidden mx-auto" />
            <img src={ProductCupMobile} className="flex md:hidden w-[90%] mx-auto" />

            {/* 파란색 글자 부분 */}
            {isKorean ? (
                <div
                    className=" font-extrabold text-white  md:py-[50px] py-[46px] text-center md:text-[50px] text-[32px]"
                    style={{
                        background:
                            "linear-gradient(90deg, rgba(114, 63, 224, 0.00) 0%, rgba(114, 63, 224, 0.50) 50.52%, rgba(114, 63, 224, 0.00) 100%)",
                    }}>
                    미스트<span className="text-[#5F5F5F]">가</span> 전세게 최초
                    <br className="md:hidden flex" /> Smoke To Earn
                    <span className="text-[#5F5F5F]">
                        을<br className="md:hidden flex" />
                    </span>
                    시작
                    <span className="text-[#5F5F5F]">
                        합니다.
                        <br />
                    </span>
                    지금 바로, 제네시스
                    <br className="md:hidden flex" /> 마이닝에 참여하세요.
                </div>
            ) : (
                <div
                    className=" font-extrabold text-white  md:py-[50px] py-[46px] text-center md:text-[50px] text-[32px]"
                    style={{
                        background:
                            "linear-gradient(90deg, rgba(114, 63, 224, 0.00) 0%, rgba(114, 63, 224, 0.50) 50.52%, rgba(114, 63, 224, 0.00) 100%)",
                    }}>
                    MIST Launches
                    <br className="flex md:hidden" /> the World's First Smoke To Earn
                    <br />
                    Join Genesis Mining Right Now
                </div>
            )}

            {/* 6ML product 부분 */}
            <div className="md:py-[200px] py-[100px] md:flex justify-center ">
                <img src={Product1} className="md:mr-[117px] w-[540px]" />
                <div>
                    <div
                        className=" md:py-[12px] ml-[28px] md:ml-0  py-[8px] border-[1px] border-white flex justify-center items-end text-white md:text-[24px] 
                    text-xs font-medium rounded-[100px] max-w-[128px] md:w-[220px] md:max-w-none">
                        SPECIFICATION
                    </div>
                    {/* 흰박스 부분 */}
                    <div className="md:px-[40px] md:mx-0 mx-[28px] px-[28px] pt-[62px] border-[#5F5F5F] border-[1px] rounded-[10px] grid grid-cols-2 gap-x-[40px] md:mt-[52px] mt-[20px]">
                        {Object.entries(products[0]).map(([key, value], index, array) => (
                            <div className={`mb-[62px] text-center ${index === array.length - 1 ? "col-span-2" : ""}`}>
                                <div className="text-white md:text-[40px] text-[28px] font-black mb-[12px]">
                                    {value}
                                </div>
                                <div className="text-[#5F5F5F] md:text-[26px] text-[16px] font-medium">{key}</div>
                            </div>
                        ))}
                        ))
                    </div>
                </div>
            </div>

            <div
                className="w-full md:h-[1000px] h-[327px] bg-cover bg-center md:mt-[-800px] mt-[-400px]"
                style={{ backgroundImage: `url(${MistBg})` }}></div>
            {/* 15ML product 부분 */}
            <div className=" py-[100px] md:flex justify-center">
                {/* 15ML 이미지 변경하기 */}
                <img src={Product2} className="mr-[117px]  w-[540px]" />
                <div>
                    <div
                        className=" md:py-[12px] ml-[28px] md:ml-0  py-[8px] border-[1px] border-white flex justify-center items-end text-white md:text-[24px] 
                    text-xs font-medium rounded-[100px] max-w-[128px] md:w-[220px] md:max-w-none">
                        SPECIFICATION
                    </div>
                    {/* 흰박스 부분 */}
                    <div className="md:px-[40px] md:mx-0 mx-[28px] px-[28px] pt-[62px] border-[#5F5F5F] border-[1px] rounded-[10px] grid grid-cols-2 gap-x-[40px] md:mt-[52px] mt-[20px]">
                        {Object.entries(products[1]).map(([key, value], index, array) => (
                            <div className={`mb-[62px] text-center ${index === array.length - 1 ? "col-span-2" : ""}`}>
                                <div className="text-white md:text-[40px] text-[28px] font-black mb-[12px]">
                                    {value}
                                </div>
                                <div className="text-[#5F5F5F] md:text-[26px] text-[16px] font-medium">{key}</div>
                            </div>
                        ))}
                        ))
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Product;

