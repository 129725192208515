import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../../locales/i18n";
import TokenBg from "../../../../assets/image/smoke_token_test.png";

import useCountdownTimer from "../../../../hooks/useCountdownTimer";
const TokenRound = (props) => {
    const { data, progressStatus, isBackgroundImg } = props;
        const { t } = useTranslation();

    const targetRound = new Date();

    targetRound.setHours(targetRound.getHours() + 48);
    targetRound.setMinutes(targetRound.getMinutes() + 32);
    targetRound.setSeconds(targetRound.getSeconds() + 21);

    const remainingRoundTime = useCountdownTimer(targetRound);

    return (
        <>
            <div className="xl:px-[8%] 2xl:px-[10%] text-white bg-black md:flex-row flex flex-col items-center md:mb-[200px] mb-[100px]">
                <span className="text-white flex text-start">
                    <div className="md:mr-[100px] md:px-[10%] mx-auto mb-[72px] md:mb-0">
                        <div className="md:text-[24px] text-[9px] font-medium rounded-[100px] border-[1px] border-white md:w-[65%] w-[40%] md:py-[12px] py-[6px] text-center">
                            {progressStatus}
                        </div>
                        <div className="md:text-[26px] text-[12px] text-[#5F5F5F] mt-[44px] mb-[10px] ">1 Round</div>
                        <div className="md:text-[66px] text-[44px] font-black flex text-center">
                            <div className="md:w-[100px] w-[70px]">{48}</div>
                            <span>:</span>
                            <div className="md:w-[100px] w-[70px]">{remainingRoundTime.minutes}</div>
                            <span>:</span>
                            <div className="md:w-[100px] w-[70px]">{remainingRoundTime.seconds}</div>
                        </div>
                    </div>
                </span>
                <span>
                    <div className="md:grid md:grid-cols-2 text-white md:gap-[62px] justify-center">
                        <div>
                            <div className="md:text-[26px] text-[12px] text-[#5F5F5F]">
                                {t(`smokeToEarn.TokenRoundExchangeRate`)}
                            </div>
                            <div className="md:text-[40px] text-[28px] font-bold whitespace-nowrap">
                                {data.exchangeRate}
                            </div>
                            <div className="md:text-[30px] text-[20px] font-semibold mb-[40px] md:mb-0">
                                {t(`smokeToEarn.TokenRoundExchangeRateUnit`)}
                            </div>
                        </div>
                        <div>
                            <div className="md:text-[26px] text-[12px] text-[#5F5F5F] whitespace-nowrap">
                                {t(`smokeToEarn.TokenRoundDistribution`)}
                            </div>
                            <div className="md:text-[40px] text-[28px] font-bold whitespace-pre-wrap  mb-[40px] md:mb-0">
                                {data.distribution}
                            </div>
                        </div>
                        <div>
                            <div className="md:text-[26px] text-[12px] text-[#5F5F5F]">Hardcap</div>
                            <div className="md:text-[40px] text-[28px] font-bold whitespace-nowrap  mb-[40px] md:mb-0">
                                {data.hardcap}
                            </div>
                        </div>
                        <div>
                            <div className="md:text-[26px] text-[12px] text-[#5F5F5F]">Amount</div>
                            <div className="md:text-[40px] text-[28px] font-bold whitespace-nowrap  mb-[40px] md:mb-0">
                                {data.amount}
                            </div>
                        </div>
                    </div>
                </span>
            </div>
            {isBackgroundImg ? <img src={TokenBg} className="md:mt-[-600px] h-[634px] md:h-auto mt-[-700px]" /> : null}
        </>
    );
};

export default TokenRound;