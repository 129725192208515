import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useCookies } from "react-cookie";
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from "react-i18next";

import ProductImage from "../assets/image/product_mini_cup_pop.png"
import ProductImageMobile from "../assets/image/product_mini_cup_pop_m.png";
const ProductPopUp = (props) => {
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const { t, i18n } = useTranslation();
    const isKorean = i18n.language === "ko";

    const { isProductModal } = props;


    const [isModalOpen, setIsModalOpen] = useState(isProductModal);
    const [todayClose, setTodayClose] = useState(false);
    const [cookies, setCookie] = useCookies(["hideModalToday"]);
    

    useEffect(() => {
        setIsModalOpen(isProductModal);
    }, [isProductModal]);


    const handleCheckboxChange = (event) => {
        setTodayClose(!todayClose);
      };

      const handleCloseModal = () => {
          if (todayClose) {
              setCookie("hideModalToday", "true", { maxAge: 86400 }); // 24시간 (1일) 동안 유효한 쿠키 설정
              setIsModalOpen(false);
              
          } else {
            setIsModalOpen(false);
          }
      };


    return isMobile ? (
        <Modal
            isOpen={isModalOpen && !cookies.hideModalToday}
            // onRequestClose={handleCloseModal}
            ariaHideApp={false}
            style={{
                overlay: {
                    display: "flex",
                    background: "rgba(0, 0, 0, 0.3)",
                    alignItems: "center",
                    justifyContent: "center",
                    boxSizing: "border-box",
                },
                content: {
                    backgroundColor: "black",
                    borderWidth: "0px",
                    maxWidth: "100%",
                    width: "auto",
                    top: "50%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    transform: "translate(-50%, -50%)",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    borderRadius: "20px",
                },
            }}>
            <div className="text-white text-[30px] font-extrabold text-center">ZIGLE MIST</div>
            <div
                className="w-[300px] h-[300px] bg-cover bg-center flex justify-center mx-auto"
                style={{
                    backgroundImage: `url(${ProductImageMobile})`,
                }}></div>

            {isKorean ? (
                <div
                    className=" font-extrabold text-white  py-[10px]  text-center text-[14px]"
                    style={{
                        background:
                            "linear-gradient(90deg, rgba(114, 63, 224, 0.00) 0%, rgba(114, 63, 224, 0.50) 50.52%, rgba(114, 63, 224, 0.00) 100%)",
                    }}>
                    미스트<span className="text-[#5F5F5F]">가</span> 전세게 최초 Smoke to earn
                    <span className="text-[#5F5F5F] mr-[3px]">을</span>
                    시작
                    <span className="text-[#5F5F5F]">
                        합니다.
                        <br />
                    </span>
                    지금 바로, 제네시스 마이닝에 참여하세요.
                </div>
            ) : (
                <div
                    className=" font-extrabold text-white  py-[10px]  text-center text-[14px]"
                    style={{
                        background:
                            "linear-gradient(90deg, rgba(114, 63, 224, 0.00) 0%, rgba(114, 63, 224, 0.50) 50.52%, rgba(114, 63, 224, 0.00) 100%)",
                    }}>
                    MIST Launches the World's First Smoke To Earn Join Genesis Mining Right Now
                </div>
            )}

            <div className="flex mt-[20px] text-white  font-semibold justify-between">
                <div>
                    <input type="checkbox" id="hideToday" checked={todayClose} onChange={handleCheckboxChange} />
                    <label className=" ml-[15px] ">{t(`productPopUp.noShownToday`)}</label>
                </div>
                <div className="cursor-pointer" onClick={() => handleCloseModal()}>
                    {t(`productPopUp.close`)}
                </div>
            </div>
        </Modal>
    ) : (
        <Modal
            isOpen={isModalOpen && !cookies.hideModalToday}
            // onRequestClose={handleCloseModal}
            ariaHideApp={false}
            style={{
                overlay: {
                    display: "flex",
                    zIndex: 30,
                    background: "rgba(0, 0, 0, 0.3)",
                    alignItems: "center",
                    justifyContent: "center",
                    boxSizing: "border-box",
                },
                content: {
                    backgroundColor: "black",
                    borderWidth: "0px",
                    maxWidth: "1000px",
                    width: "auto",
                    top: "50%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    transform: "translate(-50%, -50%)",
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    borderRadius: "20px",
                },
            }}>
            <div className="text-white text-[64px] font-extrabold text-center">ZIGLE MIST</div>
            <div
                className="w-[400px] h-[400px] bg-cover bg-center flex justify-center mx-auto"
                style={{
                    backgroundImage: `url(${ProductImage})`,
                }}></div>
            {isKorean ? (
                <div
                    className=" font-extrabold text-white  py-[20px]  text-center text-[30px]"
                    style={{
                        background:
                            "linear-gradient(90deg, rgba(114, 63, 224, 0.00) 0%, rgba(114, 63, 224, 0.50) 50.52%, rgba(114, 63, 224, 0.00) 100%)",
                    }}>
                    미스트<span className="text-[#5F5F5F]">가</span> 전세게 최초
                    <br className="md:hidden flex" /> Smoke to earn
                    <span className="text-[#5F5F5F]">
                        을<br className="md:hidden flex" />
                    </span>
                    시작
                    <span className="text-[#5F5F5F]">
                        합니다.
                        <br />
                    </span>
                    지금 바로, 제네시스
                    <br className="md:hidden flex" /> 마이닝에 참여하세요.
                </div>
            ) : (
                <div
                    className=" font-extrabold text-white  py-[20px]  text-center text-[28px]"
                    style={{
                        background:
                            "linear-gradient(90deg, rgba(114, 63, 224, 0.00) 0%, rgba(114, 63, 224, 0.50) 50.52%, rgba(114, 63, 224, 0.00) 100%)",
                    }}>
                    MIST Launches the World's First Smoke To Earn
                    <br />
                    Join Genesis Mining Right Now
                </div>
            )}

            <div className="flex mt-[20px] text-white text-[18px] font-semibold justify-between">
                <div>
                    <input type="checkbox" id="hideToday" checked={todayClose} onChange={handleCheckboxChange} />
                    <label className=" ml-[15px] ">{t(`productPopUp.noShownToday`)}</label>
                </div>
                <div className="cursor-pointer" onClick={() => handleCloseModal()}>
                    {t(`productPopUp.close`)}
                </div>
            </div>
        </Modal>
    );
};

export default ProductPopUp;
