import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import useNumberAnimation from "../../../hooks/useNumberAnimation";
import useCountdownTimer from "../../../hooks/useCountdownTimer";
import TokenRound from "./components/TokenRound";
import Card from "../../../assets/icon/ic_smoke_card.svg";
import Phone from "../../../assets/icon/ic_smoke_phone.svg";
import Coin from "../../../assets/icon/ic_smoke_coin.svg";
import Human from "../../../assets/icon/ic_smoke_human.svg";
import Buliding from "../../../assets/icon/ic_smoke_buliding.svg";
import Tornado from "../../../assets/image/smoke_tornado.png";
import TornadoMobile from "../../../assets/image/smoke_tornado_m.png";
import Earth from "../../../assets/image/smoke_earth.png";
import EarthBg from "../../../assets/image/img_background_earth.png";
import PurpleBg from "../../../assets/image/smoke_purple_bg@3x.png";
import EngagementHistory from "./components/EngagementHistory";
import humanImage from "../../../assets/image/smoke_ engagementHistory.png";




const SmokeToEarn = () => {
    const { t, i18n } = useTranslation();
    const isKorean = i18n.language === "ko";
    const [graphCount, refGraphCount] = useNumberAnimation(40, 1000, 3);

    let [minedToken, refMinedToken] = useNumberAnimation(15563, 3000, 30);

    

    const targetDate = new Date();
    targetDate.setDate(targetDate.getDate() + 2);
    targetDate.setHours(targetDate.getHours() + 8);
    targetDate.setMinutes(targetDate.getMinutes() + 25);
    targetDate.setSeconds(targetDate.getSeconds() + 54);

    const remainingTime = useCountdownTimer(targetDate);

    if(minedToken === 15563){
        minedToken = t(`intro.updateScheduled`);
    }

    const Graphics1 = [
        {
            image: Human,
            content: t(`smokeToEarn.graphicTitle01`),
        },
        {
            image: Buliding,
            content: t(`smokeToEarn.graphicTitle02`),
        },
    ];
    const Graphics2 = [
        {
            image: Card,
            content: t(`smokeToEarn.graphicTitle03`),
        },
        {
            image: Phone,
            content: t(`smokeToEarn.graphicTitle04`),
        },
        {
            image: Coin,
            content: t(`smokeToEarn.graphicTitle05`),
        },
    ];
    
    const RoundData = [
        {
            round: "48:32:21",
            exchangeRate: "1 ETH = 15,000 MIST",
            distribution: t(`smokeToEarn.TokenRoundDistributionAmount`),
            hardcap: t(`smokeToEarn.TokenRoundHardcapAmount`),
            amount: "1,000,000,000 MIST",
        },
        {
            round: "48:32:21",
            exchangeRate: "1 ETH = 15,000 MIST",
            distribution: t(`smokeToEarn.TokenRoundDistributionAmount`),
            hardcap: t(`smokeToEarn.TokenRoundHardcapAmount`),
            amount: "1,000,000,000 MIST",
        },
    ];

    return (
        <div id="SmokeToEarnSection" className="whitespace-pre-wrap">
            <div className="md:py-[200px] py-[100px] bg-black ">
                {/* 그래픽 이미지 첫번째 */}
                <div className="flex justify-around   md:pb-[60px]  md:px-[16%]  px-[2%] ">
                    {Graphics1.map((item) => (
                        <div className="flex flex-col items-center relative" ref={refMinedToken}>
                            <img src={item.image} className="w-[100px] md:w-auto" />
                            <div className="text-[#5F5F5F] md:text-[26px] text-[12px] mt-[44px] mb-[20px]">
                                {item.content}
                            </div>
                            <div className="md:text-[52px] text-[26px] font-black text-white md:h-[100px]">
                                {minedToken}
                            </div>
                            <div
                                className="md:w-[450px]  md:h-[450px] md:mt-[-300px] hidden md:flex"
                                style={{
                                    backgroundImage: `url(${Tornado})`,
                                }}></div>
                            <div className="w-[180px] h-[200px] mt-[-130px] flex justify-center items-center md:hidden">
                                <img src={TornadoMobile} alt="Tornado" />
                            </div>
                        </div>
                    ))}
                </div>

                {/* 그래픽 이미지 두번째 */}
                <div
                    style={{
                        backgroundImage: `url(${PurpleBg})`,
                    }}
                    className="bg-cover bg-center md:px-[16%] px-[20px] ">
                    <div className="text-white md:text-[50px] text-[28px] font-bold mt-[200px] ">
                        {t(`smokeToEarn.title01`)}
                    </div>
                    <div className="flex justify-around md:mt-[100px] mt-[62px]">
                        {Graphics2.map((graphic, index) => (
                            <div className="flex flex-col items-center ">
                                <img src={graphic.image} />
                                <div className="md:w-[36px] md:h-[36px] w-[20px] h-[20px] border-white border-[1px] md:text-[24px] text-[12px] text-white md:mt-[44px] mt-[24px] flex justify-center items-center">
                                    {index + 1}
                                </div>
                                <div className="md:text-[28px] text-[16px] md:mt-[20px] mt-[8px] text-white text-center">
                                    {graphic.content}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="text-white md:text-[28px] text-[14px] md:mt-[104px] mt-[62px] md:px-[16%] px-[20px]">
                    {t(`smokeToEarn.contents01`)}
                </div>

                {isKorean ? (
                    <div
                        className="md:py-[50px] py-[46px] text-white md:text-[50px] text-[32px] font-extrabold text-center md:mt-[200px] mt-[100px]"
                        style={{
                            background:
                                "linear-gradient(90deg, rgba(95, 95, 95, 0.00) 0%, rgba(95, 95, 95, 0.50) 50.52%, rgba(95, 95, 95, 0.00) 100%)",
                        }}>
                        전세계 최초
                        <span className="text-[#5F5F5F]">
                            로<br className="md:hidden" />
                        </span>{" "}
                        대한민국
                        <span className="text-[#5F5F5F]">에서</span>
                        <br /> 제네시스 마이닝
                        <span className="text-[#5F5F5F]">
                            이<br className="md:hidden" />
                        </span>{" "}
                        시작
                        <span className="text-[#5F5F5F]">됩니다.</span>
                    </div>
                ) : (
                    <div
                        className="md:py-[50px] py-[46px] text-white md:text-[50px] text-[32px] font-extrabold text-center md:mt-[200px] mt-[100px]"
                        style={{
                            background:
                                "linear-gradient(90deg, rgba(95, 95, 95, 0.00) 0%, rgba(95, 95, 95, 0.50) 50.52%, rgba(95, 95, 95, 0.00) 100%)",
                        }}>
                        A World-First Groundbreaking Launch of
                        <br /> Genesis Mining in South Korea
                    </div>
                )}

                {/* 지구모양 부분 */}

                <div className="md:pt-[146px] pt-[100px] bg-black flex md:flex-row md:justify-center flex-col-reverse md:space-around items-center md:px-[17%] px-[20px]">
                    <img src={Earth} />
                    <div className="flex-wrap md:mt-[65px] md:ml-[4%] mb-[29px]">
                        <div className="text-[#5F5F5F] md:text-[42px] text-[28px] font-extrabold">
                            {t(`smokeToEarn.limitedQuantity`)}
                            <br />
                            <span className="text-white whitespace-nowrap">
                                {t(`smokeToEarn.limitedQuantityAmount`)}
                            </span>
                        </div>
                        <div className="text-[#5F5F5F] md:text-[42px] text-[28px] font-extrabold md:mt-[62px] mt-[44px]">
                            {t(`smokeToEarn.benefits`)}
                            <br />
                            <span className="text-white md:whitespace-nowrap whitespace-wrap">
                                {t(`smokeToEarn.additionalMining`)}
                                <br />
                                <span className="md:text-[32px] text-[20px] font-medium">1000 MIST +2000 MIST</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    className="w-full h-[650px] bg-cover bg-center mt-[-650px]"
                    style={{ backgroundImage: `url(${EarthBg})` }}></div>

                {/* 토큰 세일 부분 */}
                <div className="md:pt-[200px] pt-[100px] md:pb-0 pb-[60px]  bg-black text-center md:px-[17%] px-[20px]">
                    <div className="md:text-[32px] text-[24px] font-extrabold  text-white">
                        {t(`smokeToEarn.depositAddress`)}
                    </div>
                    <div className="md:text-[28px]  text-[16px] text-white md:mt-[48px] mt-[28px] md:mb-[14px] mb-[8px] break-words ">
                        0xe6134714377b4E2EC09275e08DdCB35bE7cFa352
                    </div>
                    <div className="md:text-[22px] text-[10px] text-[#5F5F5F]">
                        {t(`smokeToEarn.depositAddressMsg`)}
                    </div>

                    <div className="flex justify-center mt-[132px]">
                        <div className="w-[220px] border-[#5F5F5F]  text-center border-r-[1px]">
                            <div className="text-white md:text-[68px] text-[36px] font-bold">{remainingTime.days}</div>
                            <div className="text-[#5F5F5F] font-medium md:text-[28px] text-[14px]">DAY</div>
                        </div>
                        <div className="w-[220px] border-[#5F5F5F]  text-center border-r-[1px]">
                            <div className="text-white md:text-[68px] text-[36px] font-bold">{remainingTime.hours}</div>
                            <div className="text-[#5F5F5F] font-medium md:text-[28px] text-[14px]">HOUR</div>
                        </div>
                        <div className="w-[220px] border-[#5F5F5F]  text-center border-r-[1px]">
                            <div className="text-white md:text-[68px] text-[36px] font-bold">
                                {remainingTime.minutes}
                            </div>
                            <div className="text-[#5F5F5F] font-medium md:text-[28px] text-[14px]">MIN</div>
                        </div>
                        <div className="w-[220px] border-[#5F5F5F]  text-center ">
                            <div className="text-white md:text-[68px] text-[36px] font-bold">
                                {remainingTime.seconds}
                            </div>
                            <div className="text-[#5F5F5F] font-medium md:text-[28px] text-[14px]">SEC</div>
                        </div>
                    </div>
                </div>

                {/* 보라색 그래프 부분*/}
                <div className="md:px-[17%] px-[20px]">
                    <div className="md:h-[68px] h-[40px] text-white  bg-[#1F1F1F] rounded-[100px] md:mt-[72px] mt-[24px] overflow-hidden ">
                        <div
                            className="h-full duration-1000 bg-[#723FE0] md:text-[32px] text-[20px] font-black rounded-[100px] md:px-[27px] px-[16px] text-end md:py-[10px] py-[5px]"
                            style={{ width: `${Math.min(graphCount * 2.5, 50)}%` }}
                            ref={refGraphCount}>
                            {graphCount}%
                        </div>
                    </div>
                    <div className="md:text-[28px] text-[14px] font-medium text-white md:mt-[28px] mt-[12px]  text-center">
                        400,000,000 <span className="text-[#5F5F5F]">/ 1,000,000,000</span> MIST
                    </div>
                </div>
            </div>
            <div className="bg-black flex flex-col items-center">
                <TokenRound data={RoundData[0]} progressStatus={t(`smokeToEarn.TokenRoundOnGoing`)} />
                <TokenRound data={RoundData[1]} progressStatus={t(`smokeToEarn.TokenRoundScheduled`)} isBackgroundImg />
            </div>
            {/* 내 참여내역 */}
            <div className="md:py-[200px] py-[100px] bg-black text-white md:px-[16%] px-[20px] relative overflow-x-hidden">
                <EngagementHistory />
                <img
                    src={humanImage}
                    className="md:w-[700px] hidden md:flex md:h-[700px] absolute md:mt-[-600px] mt-[-450px] xl:ml-[500px] 2xl:ml-[700px] brightness-50 opacity-60 mix-blend-screen 
                    "
                    style={{ pointerEvents: "none" }}
                />
            </div>
        </div>
    );
};

export default SmokeToEarn;
