import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import i18n from "../locales/i18n";
import { useMediaQuery } from 'react-responsive';

import ProductPopUp from "./ProductPopUp";
import MistBg from "../assets/image/img_background_product.png";

const PopUp = () => {
    const [IsModalOpen, setIsModalOpen] = useState(true);
    const [isProductModal, setIsProductModal] = useState(false);
    // const [cookies, setCookie] = useCookies(["mistPopUP"]);
    const { t } = useTranslation();

    const isMobile = useMediaQuery({ maxWidth: 768 });

    // useEffect(() => {
    //     const popupShown = cookies.mistPopUP;

    //     if (!popupShown) {
    //         setIsModalOpen(true);
    //     }
    // }, [cookies]);

    const handleCloseModal = () => {
        setIsModalOpen(false);
        // setCookie("mistPopUP", "true", { path: "/", maxAge: 7 * 24 * 60 * 60 }); //일주일 저장
        setIsProductModal(true);
    };

    
    return isMobile ? (
        <>
            <ProductPopUp isProductModal={isProductModal} />
            <Modal
                isOpen={IsModalOpen}
                onRequestClose={handleCloseModal}
                ariaHideApp={false}
                style={{
                    overlay: {
                        display: "flex",
                        background: "rgba(0, 0, 0, 0.3)",
                        alignItems: "center",
                        justifyContent: "center",
                        boxSizing: "border-box",
                    },
                    content: {
                        backgroundColor: "black",
                        borderWidth: "0px",
                        maxWidth: "100%",
                        width: "auto",
                        top: "50%",
                        left: "50%",
                        right: "auto",
                        bottom: "auto",
                        transform: "translate(-50%, -50%)",
                        backgroundImage: `url(${MistBg})`,
                        backgroundSize: "cover",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                    },
                }}>
                <p className="text-[white]  text-[26px] font-bold">{t(`popUp.title`)}</p>
                <div className="flex  text-[20px] text-white mt-[58px]">
                    <div
                        className="w-[116px] h-[53px] flex justify-center items-center border-[1px] border-white mr-[20px] cursor-pointer"
                        onClick={() => handleCloseModal()}>
                        {t(`popUp.yes`)}
                    </div>
                    <div className=" w-[116px]  h-[53px] flex justify-center items-center border-[1px] border-white cursor-pointer">
                        {t(`popUp.no`)}
                    </div>
                </div>
                <p className="text-[white] mt-[80px] text-[12px] font-medium">{t(`popUp.subTitle`)}</p>
            </Modal>
        </>
    ) : (
        <>
            <ProductPopUp isProductModal={isProductModal} />
            <Modal
                isOpen={IsModalOpen}
                onRequestClose={handleCloseModal}
                ariaHideApp={false}
                style={{
                    overlay: {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "rgba(0, 0, 0, 0.3)",
                    },
                    content: {
                        position: "relative",
                        inset: "unset",
                        alignItems: "center",
                        borderWidth: "0px",
                        backgroundColor: "black",
                        padding: "80px",
                        backgroundImage: `url(${MistBg})`,
                        backgroundSize: "cover",
                    },
                }}>
                <p className="text-[white] text-[45px] font-bold whitespace-pre-wrap">{t(`popUp.title`)}</p>
                <div className="flex text-[40px]  text-white mt-[58px]">
                    <div
                        className="w-[191px] h-[76px] flex justify-center items-center border-[1px] border-white mr-[20px] cursor-pointer"
                        onClick={() => handleCloseModal()}>
                        {t(`popUp.yes`)}
                    </div>
                    <div className="w-[191px]  h-[76px]  flex justify-center items-center border-[1px] border-white cursor-pointer">
                        {t(`popUp.no`)}
                    </div>
                </div>
                <p className="text-[white] mt-[80px] font-medium whitespace-pre-wrap">{t(`popUp.subTitle`)}</p>
            </Modal>
        </>
    );

};

export default PopUp;
