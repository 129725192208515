import React from "react";
import { useTranslation } from "react-i18next";

import EconomyTokenPc from "../../assets/image/economy_token_pc@3x.png";
import EconomyTokenMobile from "../../assets/image/economy_token_m@3x.png";
import EconomyTokenPcEn from "../../assets/image/EconomyTokenPcEnglish@3x.png";

const Economy = () =>{
    const { t, i18n } = useTranslation();
    const isKorean = i18n.language === "ko";
    return (
        <div id="EconomySection" className="bg-black w-full md:pt-[144px] pt-[100px]">
            <div className="text-white md:text-[52px] text-[24px] font-extrabold md:mb-[100px] mb-[80px] md:px-[18%] px-[20px]">
                {t(`economy.title`)}
            </div>
            {isKorean ? (
                <>
                    <img src={EconomyTokenPc} className="hidden md:flex px-[26%]" />
                    <img src={EconomyTokenMobile} className="flex md:hidden w-[335px] h-[229px] mx-auto" />
                </>
            ) : (
                <>
                    <img src={EconomyTokenPcEn} className="hidden md:flex px-[26%]" />
                    <img src={EconomyTokenPcEn} className="flex md:hidden w-[335px] h-[229px] mx-auto" />
                </>
            )}
        </div>
    );
}

export default Economy;

