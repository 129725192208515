import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import BlurImg from "../../../../assets/image/smoke_blur.png";
import BlurImgMobile from "../../../../assets/image/smoke_blur_m.png";
const EngagementHistory = (props) => {
    const { t,i18n } = useTranslation();
    const [userAddress, setUserAddress] = useState("");
    const [searchResults, setSearchResults] = useState();
    const [searchClicked, setSearchClicked] = useState(false);

    const isKorean = i18n.language === "ko";

    const handleSearch = () => {
        if (!userAddress) {
            {
                isKorean ? window.alert("지갑주소를 입력해주세요") : window.alert("Please enter a wallet address.");
            }
           
        } else {
            axios
                .get(`https://api.zigle.io/api/v1/anony/trans/buyList/mist/${userAddress}`)
                .then((response) => {
                    setSearchResults(response.data);
                    console.log(searchResults);
                    console.log(response.data);
                })
                .catch((error) => {
                    window.alert("올바른 지갑주소를 입력해주세요");
                });
        }
    };




    return (
        <div>
            <div>
                <p className="md:text-[40px] text-[28px] font-extrabold md:mb-[52px] mb-[44px]">
                    {t(`smokeToEarn.EngagementHistoryTitle`)}
                </p>
                <div className="flex">
                    <input
                        className="bg-[#1F1F1F] md:w-[80%]  w-[65%] md:h-[68px] h-[52px] md:mr-[24px] mr-[17px] md:text-[30px] text-[20px] pl-[10px]"
                        onChange={(event) => setUserAddress(event.target.value)}
                    />
                    <span
                        className="md:w-[20%] w-[35%] md:h-[68px] h-[53px] flex justify-center items-center font-semibold md:text-[30px] text-[20px] border-[1px] border-white cursor-pointer"
                        onClick={() => handleSearch()}>
                        {t(`smokeToEarn.EngagementHistoryButton`)}
                    </span>
                </div>
            </div>

            {searchResults ? (
                <div className="xl:py-[55px] px-[40px] pt-[20px] pb-[80px] 2xl:px-[106px] xl:px-[60px] border-[1px] border-[#5F5F5F] mt-[44px] rounded-[10px] xl:flex justify-around relative ">
                    {!searchResults && (
                        <p
                            className="md:text-[28px] text-[14px] text-center absolute top-1/2 md:right-1/3  right-1/4 h-[48px] flex  justify-center items-center"
                            style={{
                                background:
                                    "linear-gradient(90deg, rgba(95, 95, 95, 0.00) 0%, rgba(95, 95, 95, 0.30) 50.52%, rgba(95, 95, 95, 0.00) 100%)",
                            }}>
                            {t(`smokeToEarn.EngagementHistoryMsg`)}
                        </p>
                    )}
                    <div
                        className="pr-[79px] xl:border-r-[1px] border-b-[1px] xl:border-b-0 border-[#5F5F5F] 2xl:text-[28px] xl:text-[24px] text-[14px]
                    ">
                        <p className="font-medium xl:mb-[66px] mb-[12px]">1 Round</p>
                        <p className="text-white mb-[40px] xl:mb-0">{t(`smokeToEarn.EngagementHistoryNoMsg`)}</p>
                    </div>
                    <div className="2xl:pl-[79px] xl:pl-[60px]">
                        <p className="2xl:text-[28px] xl:text-[24px] font-medium mb-[36px] mt-[40px] xl:mt-0 text-[14px]">
                            2 Round
                        </p>
                        <p className="text-white 2xl:text-[32px] xl:text-[28px] text-[16px] font-semibold">
                            {t(`smokeToEarn.EngagementHistoryQuantity`)}
                        </p>
                        <p className="text-white 2xl:text-[42px] xl:text-[38px] text-[28px] font-black">1.5 ETH</p>
                    </div>
                    <div className="flex flex-col justify-between">
                        <p className="2xl:text-[22px]  xl:text-[18px] font-medium  text-end">
                            {`${searchResults?.txDate?.substring(0, 4)}.${searchResults?.txDate?.substring(
                                4,
                                6
                            )}.${searchResults?.txDate?.substring(6, 8)}`}
                        </p>
                        <div>
                            <p className="text-white 2xl:text-[32px] xl:text-[28px] text-[16px] font-semibold">
                                {t(`smokeToEarn.EngagementHistoryPayment`)}
                            </p>
                            <p className="text-white 2xl:text-[42px] xl:text-[38px] text-[28px] font-black">
                                15,000,000 MIST
                            </p>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="relative pt-[20px] xl:py-[55px]">
                    <img src={BlurImg} className="hidden md:flex w-full h-auto" alt="Background Image" />
                    <img src={BlurImgMobile} className="md:hidden w-full h-auto" alt="Background Image" />
                    <div className="absolute inset-0 flex items-center justify-center bg-gradient-to-r  to-transparent md:text-[28px] text-[14px]">
                        {t(`smokeToEarn.EngagementHistoryMsg`)}
                    </div>
                </div>
            )}
        </div>
    );
};

export default EngagementHistory;
