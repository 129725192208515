import React, { lazy, Suspense } from "react";
import Michael from "../../assets/team/michael@2x.png";
import JinshengLong from "../../assets/team/jinshengLong@2x.png";
import Zegaria from "../../assets/team/zegaria@2x.png";
import JunXu from "../../assets/team/junXu@2x.png";
import Will from "../../assets/team/will@2x.png";
import MengfanXie from "../../assets/team/mengfanXie@2x.png";

const Team = () => {
    const members = [
        {
            name: "Michael",
            position: "CEO",
            src: Michael,
        },
        {
            name: "Jinsheng Long",
            position: "CTO",
            src: JinshengLong,
        },
        {
            name: "Zegaria",
            position: "CEO",
            src: Zegaria,
        },
        {
            name: "Dr,Jun Xu",
            position: "Technical Advisor",
            src: JunXu,
        },
        {
            name: "Will",
            position: "CIO",
            src: Will,
        },
        {
            name: "Mengfan Xie",
            position: "R&D ",
            src: MengfanXie,
        },
    ];
    return (
        <div id="TeamSection" className="md:py-[144px] py-[100px] bg-black">
            <div className="font-extrabold md:text-[50px] text-[28px] text-white md:mb-[100px] mb-[42px] text-center">
                TEAM
            </div>

            {/* 멤버 리스트 */}
            <div className="grid grid-cols-3  md:w-2/3 md:mx-auto mx-[15px] md:gap-x-[48px] gap-x-[10px] md:gap-y-[85px] gap-y-[42px]">
                {members.map((member) => (
                    <div className="flex flex-col items-center">
                        <img src={member.src} className="md:w-[158px] md:h-[158px] w-[100px] h-[100px]" />
                        <div className="md:text-lg text-sm text-white mt-[17px] mb-[5px]">{member.name}</div>
                        <div className="text-white text-xs md:text-base">{member.position}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Team;
